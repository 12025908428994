<template>
  <div>
    <plc @deviceData="deviceDatas" :columnNames="columnName"></plc>
  </div>
</template>
  
<script>
import Plc from './plc'
export default {
  components: {
    Plc
  },
  props: {
    columnNameArrs: {
      type: Array
    }
  },
  data() {
    return {
      columnName: this.columnNameArrs
    };
  },
  methods: {
    deviceDatas(val) {
      this.$emit('updateDeviceData',val)
    },
  },
  mounted() {
    
  },
};
</script>
  
<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
</style>
<template>
    <div class="white-bg-card">
        <!-- 设备类型 -->
        <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title-custom" style="margin-bottom:0;">设备信息</div>
            <el-button type="primary" size="small" @click="addDevice">新增设备</el-button>
        </div>
        <!-- 循环设备Tag -->
        <el-form :model="plcForm" label-width="100px">
            <div class="custom-attr" v-for="(dItem,dIndex) in deviceData" :key="dIndex">
                <div class="custom-attr-title">
                    <span class="title-name">设备</span>
                    <div v-if="dIndex!=0" class="close-icon" @click="delDevice(dIndex)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <el-row :gutter="0">
                    <el-col :span="6">
                        <el-form-item label="设备名称">
                            <el-input v-model="dItem.equipmentName" size="small" style="width: 60%;" disabled placeholder="请选择设备"></el-input>
                            <el-button type="primary" size="small" @click="selectDevice(dItem)">选择设备</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <div class="custom-attr-title">
                    <span class="title-name">点表</span>
                    <el-button type="primary" size="small" @click="addTag(dItem.attributes)">新增Tag项</el-button>
                </div>
                <div class="custom-attr" style="padding: 10px 0;background: rgb(249 249 249);border: 0;margin-bottom: 10px;" v-for="(aItem,aIndex) in dItem.attributes" :key="aIndex">
                    <el-row :gutter="0">
                        <el-col :span="6">
                            <el-form-item label="点表名称">
                                <el-input v-model="aItem.tag" size="small" style="width: 60%;" disabled placeholder="请选择点表"></el-input>
                                <el-button type="primary" size="small" @click="selectPoint(dItem,aItem)" :disabled="!dItem.equipmentName">选择点表</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="点表描述">
                                <el-input v-model="aItem.describe" size="small" :disabled="!aItem.pointName" style="width: 100%;" placeholder="请输入描述" @change="changeDataType"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="点表单位">
                                <el-input v-model="aItem.unit" size="small" :disabled="!aItem.pointName" style="width: 100%;" placeholder="请输入单位" @change="changeDataType"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="统计类别">
                                <el-select v-model="aItem.dataHanding" size="small" :disabled="!aItem.pointName" style="width:100%;" placeholder="请选择统计类别" @change="changeDataType">
                                <el-option
                                    v-for="item in dataHandings"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="所属列">
                                <el-select v-model="aItem.index" size="small" :disabled="!aItem.pointName" style="width:100%;" placeholder="请选择所属列" @change="changeDataType">
                                <el-option
                                    v-for="(item,index) in columnDatas"
                                    :label="item.name"
                                    :value="index+1"
                                    :key="index+1"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <div v-if="aIndex!=0" class="close-icon" style="margin-top: 13px;" @click="delTag(dItem.attributes,aIndex)">
                                <i class="el-icon-close"></i>
                            </div>
                        </el-col>
                    </el-row>
                    <!-- 选择点表 -->
                    <el-drawer
                        title=""
                        :with-header="false"
                        :visible.sync="aItem.tagDrawer"
                        direction="rtl"
                        size="1000px">
                        <div class="drawer-title">选择点表</div>
                        <div class="drawer-content table-tree">
                            <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                                <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                    <el-radio-group v-model="aItem.tagRadio" @change="checkTagChange(scope.row,aItem)">
                                        <el-radio :label="scope.row.id">{{''}}</el-radio>
                                    </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                <el-table-column prop="pointName" label="点名"></el-table-column>
                                <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                <el-table-column prop="cycle" label="采集周期"></el-table-column>
                            </el-table>
                        </div>
                        <div class="drawer-bottom">
                            <el-button size="small" type="primary" @click="aItem.tagDrawer=false">取消</el-button>
                            <el-button size="small" type="primary" @click="deviceDrawerOK(aItem)" class="drawer-bottom-ok">确 定</el-button>
                        </div>
                    </el-drawer>
                </div>
                <!-- 选择设备 -->
                <el-drawer
                    title=""
                    :with-header="false"
                    :visible.sync="dItem.drawer"
                    direction="rtl"
                    size="1000px">
                    <div class="drawer-title">选择设备</div>
                    <div class="drawer-content">
                        <div class="table-tree">
                            <div class="tree">
                                <div class="tree-title">
                                    <div>
                                        <i class="el-icon-share" style="margin-right:10px;"></i>设备类型
                                    </div>
                                </div>
                                <div class="tree-park" v-for="(item,index) in supplierData" :key="index">
                                    <div class="park-title" :class="current==item.equipmentBrandCode?'bright':''" @click.prevent="brandToggle(item.equipmentBrandCode)">
                                    <span class="class-icon iconfont icon-xiangmu" :style="current==item.equipmentBrandCode?'color:#FFF;':'color:#3498db;'"></span>
                                    <span>{{item.equipmentBrand}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="table">
                                <el-table :data="tableData" style="width: 99%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                                    <el-table-column label="勾选" width="70">
                                        <template slot-scope="scope">
                                            <el-radio-group v-model="dItem.configRadio" @change="checkConfig(scope.row,dItem)">
                                                <el-radio :label="scope.row.eid">{{''}}</el-radio>
                                            </el-radio-group>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="waterworks" label="水厂">
                                        <template slot-scope="scope">
                                        {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="position" label="泵房编号">
                                        <template slot-scope="scope">
                                        {{ scope.row.position?scope.row.position:'-' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="equipmentName" label="设备名称">
                                        <template slot-scope="scope">
                                        {{scope.row.equipmentName?scope.row.equipmentName:'-'}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="eid" label="设备号"></el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <div class="drawer-bottom">
                        <el-button size="small" type="primary" @click="dItem.drawer=false">取消</el-button>
                        <el-button size="small" type="primary" @click="drawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                    </div>
                </el-drawer>
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    props: {
        columnNames: {
            type: Array
        }
    },
    data() {
        return {
            plcForm: {},
            supplierData: [],
            tableData: [],
            treeLoading: false,
            tableLoading: false,
            abilityLoading: false,
            current: null,
            selectCache: {},
            selectConfigObj: {},
            dataHandings: [
                { code: 'original', name: '按原始数据' },
                { code: 'subtraction', name: '按已处理数据' },
            ],
            deviceData: [
                {
                    drawer: false,
                    configRadio: '',
                    gatewayEid: null,
                    eid: null,
                    equipmentBrandCode: null,
                    equipmentName: null,
                    attributes: [{
                        tag: '',
                        describe: '',
                        tagDrawer: false,
                        tagRadio: '',
                        unit: '',
                        parameterEn: null,
                        parameterCn: null,
                        pointName: null,
                        dataHanding: '',
                        index: null
                    }]
                }
            ],
            tagTableData: [],
            columnDatas: this.columnNames
        }
    },
    methods: {
        changeDataType(e) {
            this.$forceUpdate()
        },
        changeInput(e) {
            this.$forceUpdate()
        },
        // 新增设备
        addDevice() {
            this.deviceData.push({
                drawer: false,
                configRadio: '',
                gatewayEid: null,
                eid: null,
                equipmentBrandCode: null,
                equipmentName: null,
                attributes: [{
                    tag: '',
                    describe: '',
                    tagDrawer: false,
                    tagRadio: '',
                    unit: '',
                    parameterEn: null,
                    parameterCn: null,
                    pointName: null,
                    dataHanding: '',
                    index: null
                }]
            })
            this.$forceUpdate()
        },
        // 删除设备
        delDevice(index) {
            this.deviceData.splice(index,1)
        },
        // 选择设备
        selectDevice(dItem) {
            dItem.drawer = true
            if(dItem.equipmentBrandCode) {
                this.current = dItem.equipmentBrandCode
                this.loadListData()
            }
        },
        // 勾选设备
        checkConfig(row,dItem) {
            this.selectCache = {}
            dItem.configRadio = row.eid
            this.selectCache = row
        },
        // 确认选择设备
        drawerOK(dItem) {
            dItem.gatewayEid = this.selectCache.gatewayEid
            dItem.eid = this.selectCache.eid
            dItem.equipmentName = this.selectCache.equipmentName
            dItem.equipmentBrandCode = this.selectCache.equipmentBrandCode
            this.selectConfigObj = this.selectCache
            dItem.drawer = false
            this.$forceUpdate()
            this.$emit('deviceData',this.deviceData)
        },
        // 新增Tag组
        addTag(arr) {
            arr.push({
                tag: '',
                describe: '',
                tagDrawer: false,
                tagRadio: '',
                unit: '',
                parameterEn: null,
                parameterCn: null,
                pointName: null,
                dataHanding: '',
                index: null
            })
            this.$forceUpdate()
        },
        // 删除Tag组
        delTag(arr,index) {
            arr.splice(index,1)
            this.$forceUpdate()
        },
        // 选择点表
        selectPoint(dItem,aItem) {
            aItem.tagDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 选择tag赋值点表数据
        checkTagChange(row,aItem) {
            this.tagSelectObj = row
        },
        // 确定选择设备Tag
        deviceDrawerOK(aItem) {
            aItem.pointName = this.tagSelectObj.pointName
            aItem.tag = this.tagSelectObj.pointName
            aItem.describe = this.tagSelectObj.describe
            aItem.tagDrawer = false
            this.$forceUpdate()
            this.$emit('deviceData',this.deviceData)
        },
        // 获取设备
        loadListData() {
            this.tableLoading = true
            this.$ajax.post('equipmentByEcode',{
                pageNum: 0,
                pageSize: 0,
                equipmentBrandCode: this.current,
            }).then(res => {
                res.data.forEach(v=>{
                    v.check = false
                })
                this.tableData = res.data
                this.tableLoading = false
            }).catch(err=>{
                this.tableLoading = false
            })
        },
        // 切换设备项目
        brandToggle(equipmentBrandCode) {
            this.current = equipmentBrandCode
            this.equipmentBrandCode = equipmentBrandCode
            sessionStorage.setItem('equipmentBrandCode',equipmentBrandCode)
            this.loadListData()
        },
        // 获取品牌
        loadBrand() {
            this.treeLoading = true
            this.$ajax.post('equipmentByAbilityQuery',{
                ability: 1,
            }).then(res => {
                this.supplierData = res.data
                this.current = this.supplierData[0].equipmentBrandCode
                this.equipmentBrandCode = this.supplierData[0].equipmentBrandCode
                this.loadListData()
                this.treeLoading = false
            }).catch(err=>{
                this.treeLoading = false
            })
        },
        // 获取Tag列表
        loadTagsList(eid) {
            this.abilityLoading = true
            this.$ajax.post("iotTagsQuery", {}, { 
                eid
            }).then(res=>{
                this.tagTableData = res.data
                this.abilityLoading = false
            }).catch(err=>{
                this.abilityLoading = false
            })
        }
    },
    mounted() {
        this.loadBrand()
        this.$forceUpdate()
    }
}
</script>
<style lang="less" scoped>
.table-tree {
  display: flex;
  height: ~'calc(100% - 130px)';
  margin-top: 20px;
  .tree {
    width: 260px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
  border-radius: 5px;
}
.custom-attr {
  border: 1px solid #ebeef5;
//   box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  .close-icon {
    width: 25px;
    background-color: #ff7979;
    color: #FFF;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    margin-left: 50px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
        background-color: rgba(255, 121, 121, .7);
    }
  }
}
.line {
    width: 100%;
    height: 1px;
    background: #efefef;
    padding: 0 30px;
    box-sizing: border-box;
    margin: 15px auto;
}
.custom-attr-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.title-name {
    font-size: 15px;
    position: relative;
    padding-left: 10px;
    color: #666;
    &::before {
        content: '';
        width: 4px;
        height: 15px;
        border-radius: 4px;
        background: #409EFF;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.custom-attr/deep/.el-form-item {
    margin-bottom: 0;
    margin: 5px 0;
}
.el-select {
    width: 100%;
}
.formula-type {
    display: flex;
    align-items: center;
}
.formula-left {
    background-color: #ecf5ff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    color: #409eff;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
}
.f-symbol {
    font-size: 14px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 5px;
    width: 30px;
    line-height: 30px;
    margin: 0 20px;
    color: #333;
}
.lube-btn {
    width: 100%;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #409EFF;
  line-height: 40px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
  
  .close-tagname {
    display: inline-block;
    overflow: auto;
    white-space: nowrap; 
    flex: 2;
  }
  .close-lube {
    cursor: pointer;
    padding: 10px;
  }
}
.formula-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .formula-title-left::before {
        content: '';
        border-left: 4px solid #409EFF;
        padding-left: 10px;
    }
}
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; 
  background-color: #c1c1c1;
  /* #58585B */
}
</style>
<style lang="less">
.el-drawer.rtl {
  overflow: scroll;
}
.el-tree {
    height: 750px;
    overflow: auto;
}
</style>
import { render, staticRenderFns } from "./plc.vue?vue&type=template&id=f35e59b4&scoped=true&"
import script from "./plc.vue?vue&type=script&lang=js&"
export * from "./plc.vue?vue&type=script&lang=js&"
import style0 from "./plc.vue?vue&type=style&index=0&id=f35e59b4&lang=less&scoped=true&"
import style1 from "./plc.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f35e59b4",
  null
  
)

export default component.exports